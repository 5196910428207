export function formatTitle(title: string): string {
  // Regular expression để tìm tất cả các phần "type" nằm trong dấu []
  const typeRegex = /\[.*?\]/g;

  // Lấy tất cả các phần "type" (trong dấu []) và giữ nguyên định dạng
  const types = title.match(typeRegex) || []; // Nếu không có type, trả về mảng rỗng
  let mainTitle = title.replace(typeRegex, '').trim(); // Loại bỏ phần type khỏi tiêu đề chính

  // Hàm viết hoa chữ cái đầu tiên của mỗi từ (Title Case)
  const toTitleCase = (str: string): string =>
    str
      .toLowerCase() // Chuyển toàn bộ thành chữ thường
      .replace(/\b\w/g, char => char.toUpperCase()); // Viết hoa chữ cái đầu

  // Format phần chính của tiêu đề
  mainTitle = toTitleCase(mainTitle);

  // Kết hợp lại phần chính và tất cả các type giữ nguyên định dạng
  types.forEach(type => {
    mainTitle += ` ${type}`; // Thêm lại từng type vào cuối tiêu đề
  });

  return mainTitle.trim(); // Loại bỏ khoảng trắng thừa
}
